import { createStore, StoreApi } from 'zustand/vanilla';
import { defaultMappingFunction, defaultQueryColumns, WorkItem } from '../../WorkItems/WorkItemsList';
import { FilterNode } from '../../Filtering/TableFilter';
import { getFilterStateFromUrl, removeFilterNode, saveFilterStateToURLSearchParams, setFilterNode } from '../../Filtering/StoreFunctions';
import { workItemReportingEventType } from '../../../utils/dciConstants';
import { getTableStateFromUrl, saveTableStateToURLSearchParams, TableState } from '../../Table/Stores/TableStore';
import { SetterFunction } from '../../Store/Zustand';
import { RowId } from '../../Table/Shared';
import { clearSelection, getExportCsv, globalSelectAllClick, rowSelectClick, selectAllClick, setCurrentPage, setGraphQLQueryName, setPageSize, toggleSortColumn, updateRowData } from '../../Table/StoreFunctions';
import React, { useContext } from 'react';
import { persist, PersistStorage } from 'zustand/middleware';
import { addDays, addMonths, format, min } from 'date-fns';
import { useStore } from 'zustand';
import { ChartState } from './ChartStore';
import { updateChartData } from './ChartStoreFunctions';
import { PARAMETER_TYPE, QueryParameter, registerParameterApplicator } from '../../../GraphQLShared';
import { filterColumns } from '../../WorkItems/Shared';

const WorkItemReportingStoreContext = React.createContext<StoreApi<WorkItemReportingState>>(null as any as StoreApi<WorkItemReportingState>);

const useWorkItemReportingState = <TProp,>(selector: (state: WorkItemReportingState) => TProp) => {
    const context = useContext(WorkItemReportingStoreContext);
    return useStore(context, selector);
}

type ReportType = 'Line Chart' | 'Item List';

type PeriodType = 'On' | 'Between';
const defaultPeriodType: PeriodType = 'On';

const defaultStartDate = new Date();
const defaultEndDate = null;

type ReportingEventTypeSpec = {
    displayName: string,
    name: string,
    groupings: Grouping[],
    metricType: MetricType,
    eventTypes: number[],
    level?: number
    availableReportTypes: ReportType[]
};

type Grouping = {
    displayName: string,
    name: string,
    groupingColumn: string
};

const user: Grouping = {
    displayName: 'User',
    name: 'User',
    groupingColumn: 'reportingEventUserId'
};

const regulatoryImpact: Grouping = {
    displayName: 'Regulatory Impact',
    name: 'RegulatoryImpactId',
    groupingColumn: 'regulatoryImpactId'
};

const rulePriority: Grouping = {
    displayName: 'Priority',
    name: 'RulePriority',
    groupingColumn: 'workItemPriority'
};

const workQueue: Grouping = {
    displayName: 'Work Queue',
    name: 'WorkQueue',
    groupingColumn: 'workQueueId'
};

type MetricType = 'ACTIVE' | 'OVERDUE' | 'EVENT_COUNT';

const eventTypes: ReportingEventTypeSpec[] = [
    {
        displayName: 'Active',
        name: 'Active',
        metricType: 'ACTIVE',
        eventTypes: [],
        groupings: [ regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart' ]
    },
    {
        displayName: 'Overdue',
        name: 'Overdue',
        metricType: 'OVERDUE',
        eventTypes: [],
        groupings: [ regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart' ]
    },
    {
        displayName: 'Created',
        name: 'Created',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.CREATED ],
        groupings: [ regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Distributed',
        name: 'Distributed',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.DISTRIBUTED ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Returned to Distribution',
        name: 'ReturnedToDistribution',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.RETURNED_TO_DISTRIBUTION ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Accepted',
        name: 'Accepted',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.ACCEPTED ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Marked as Information Required',
        name: 'MarkedAsInformationRequired',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.MARKED_AS_INFORMATION_REQUIRED ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Referred',
        name: 'Referred',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.REFERRED ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Referral Cancelled',
        name: 'ReferralCancelled',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.REFERRAL_CANCELLED ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Returned to Queue',
        name: 'ReturnedToQueue',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.RETURNED_TO_QUEUE ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Override Requested',
        name: 'OverrideRequested',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.OVERRIDE_REQUESTED ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Override Request Cancelled',
        name: 'OverrideRequestCancelled',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.OVERRIDE_REQUEST_CANCELLED ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Override Rejected',
        name: 'OverrideRejected',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.OVERRIDE_REJECTED ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Resolved',
        name: 'Resolved',
        metricType: 'EVENT_COUNT',
        eventTypes: [
            workItemReportingEventType.OVERRIDE_APPROVED,
            workItemReportingEventType.OVERRIDE_APPROVED_SAME_DATA_ONLY,
            workItemReportingEventType.CORRECTED,
            workItemReportingEventType.CORRECTED_AT_SOURCE
        ],
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'All Approved Overrides',
        name: 'AllApprovedOverrides',
        metricType: 'EVENT_COUNT',
        eventTypes: [
            workItemReportingEventType.OVERRIDE_APPROVED,
            workItemReportingEventType.OVERRIDE_APPROVED_SAME_DATA_ONLY
        ],
        level: 1,
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Override Approved',
        name: 'OverrideApproved',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.OVERRIDE_APPROVED ],
        level: 2,
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Override Approved for Same Value Only',
        name: 'OverrideApprovedForSameValueOnly',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.OVERRIDE_APPROVED_SAME_DATA_ONLY ],
        level: 2,
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Corrected',
        name: 'Corrected',
        metricType: 'EVENT_COUNT',
        eventTypes: [
            workItemReportingEventType.CORRECTED,
            workItemReportingEventType.CORRECTED_AT_SOURCE
        ],
        level: 1,
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Manually Corrected',
        name: 'ManuallyCorrected',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.CORRECTED ],
        level: 2,
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    },
    {
        displayName: 'Corrected at Source',
        name: 'CorrectedAtSource',
        metricType: 'EVENT_COUNT',
        eventTypes: [ workItemReportingEventType.CORRECTED_AT_SOURCE ],
        level: 2,
        groupings: [ user, regulatoryImpact, rulePriority, workQueue ],
        availableReportTypes: [ 'Line Chart', 'Item List' ]
    }
];

const defaultEventType = eventTypes.find(et => et.name === 'Created')!;

interface WorkItemReportingState extends TableState, ChartState {
    hasHydrated: boolean,
    setHasHydrated: () => void,

    // Event Types
    eventTypes: ReportingEventTypeSpec[]

    // On / Between
    periodTypes: PeriodType[]

    // Report Types
    reportTypes: ReportType[]    

    groupBy: Grouping | null
    setGroupBy: (groupBy: Grouping | null) => void

    eventType: ReportingEventTypeSpec
    setEventType: (eventType: ReportingEventTypeSpec) => void

    startDate: Date
    setStartDate: (startDate: Date) => void
    endDate: Date | null
    setEndDate: (endDate: Date) => void

    reportType: ReportType
    setReportType: (reportType: ReportType) => void

    periodType: PeriodType,
    setPeriodType: (periodType: PeriodType) => void,
    
    filterCollapsed: boolean
    setFilterCollapsed: (collapsed: boolean) => void
}

const eventTypeParamName = 'event';
const onParamName = 'on';
const betweenParamName = 'between';
const reportTypeParamName = 'reportType';
const groupByParamName = 'groupBy';

const saveReportStateToURLSearchParams = (state: WorkItemReportingState, searchParams: URLSearchParams) => {
    searchParams.set(eventTypeParamName, state.eventType.name);
    
    if (state.periodType === 'On') {
        searchParams.delete(betweenParamName);
        searchParams.set(onParamName, format(state.startDate, 'yyyy-MM-dd'));
    }
    
    if (state.periodType === 'Between') {
        searchParams.delete(onParamName);
        searchParams.set(betweenParamName, `${format(state.startDate, 'yyyy-MM-dd')}:${format(state.endDate ?? state.startDate, 'yyyy-MM-dd')}`);
    }

    searchParams.set(reportTypeParamName, state.reportType);
    if (state.groupBy === null) {
        searchParams.delete(groupByParamName);
    } else {
        searchParams.set(groupByParamName, state.groupBy.name);
    }
}

const getSavedStateUrl = (state: WorkItemReportingState) => {
    const queryStringParams = new URLSearchParams(window.location.search);

    saveReportStateToURLSearchParams(state, queryStringParams);
    saveTableStateToURLSearchParams(state, queryStringParams);
    saveFilterStateToURLSearchParams(state, queryStringParams, '');

    const newQueryString = queryStringParams.toString();
    if (newQueryString !== '') {
        return window.location.pathname + '?' + queryStringParams.toString();
    } else {
        return window.location.pathname;
    }
}

const getReportStateFromUrl = (): Partial<WorkItemReportingState> => {
    const queryStringParams = new URLSearchParams(window.location.search);
    const result: Partial<WorkItemReportingState> = {};
    
    const newEventTypeName = queryStringParams.get(eventTypeParamName);
    if (newEventTypeName) {
        const event = eventTypes.find(e => e.name === newEventTypeName);
        if (event) {
            result.eventType = event;
        }
    }

    if (result.eventType === undefined) {
        // Event type not set from URL, so set to default
        result.eventType = defaultEventType;
    }
    
    const newOnParam = queryStringParams.get(onParamName);
    if (newOnParam && /\d{4}-\d{2}-\d{2}/.test(newOnParam)) {
        const startDate = new Date(Date.parse(newOnParam));
        result.periodType = 'On';
        result.startDate = startDate;
    } else {
        const newBetweenParam = queryStringParams.get(betweenParamName);
        if (newBetweenParam && /\d{4}-\d{2}-\d{2}:\d{4}-\d{2}-\d{2}/) {
            const startDate = new Date(Date.parse(newBetweenParam.substring(0, 10)));
            const endDate = new Date(Date.parse(newBetweenParam.substring(11, 21)));
            result.periodType = 'Between';
            result.startDate = startDate;
            result.endDate = endDate;
            result.reportTypes = [ 'Item List', 'Line Chart' ]
        }
    }

    const reportTypeParam = queryStringParams.get(reportTypeParamName);
    if (reportTypeParam) {
        if ([ 'Item List', 'Line Chart' ].findIndex(i => i === reportTypeParam) !== -1) {
            if (reportTypeParam === 'Line Chart' && result.periodType === 'Between') {
                result.reportType = 'Line Chart'
            }
        }
    }

    const groupByParam = queryStringParams.get(groupByParamName);
    if (groupByParam) {
        if (result.reportType === 'Line Chart') {
            const groupBy = result.eventType!.groupings.find(g => g.name === groupByParam);
            if (groupBy !== undefined) {
                result.groupBy = groupBy;
                result.groupingColumn = groupBy.groupingColumn;
            }
        }
    }

    return result;
}

const storage: PersistStorage<WorkItemReportingState> = {
    getItem: async name => {
        if (Object.hasOwn(window.history.state, name)) {
            const state = window.history.state[name].state as WorkItemReportingState;

            // The value needs to be an object-reference match, whereas this is a copy
            // so we need to find the original
            const eventType = eventTypes.find(e => e.name === state.eventType.name);
            if (eventType) {
                state.eventType = eventType;
            }

            return window.history.state[name];
        }

        const filterState = await getFilterStateFromUrl(filterColumns);
        const state = {
            ...getTableStateFromUrl(),
            ...filterState,
            ...getReportStateFromUrl()
        }

        return {
            state: state
        }
    },
    setItem: (name, value) => {
        const urlToSave = getSavedStateUrl(value.state);

        const stateToSave = {
            ...window.history.state,
            [name]: value
        };

        window.history.replaceState(stateToSave, '', urlToSave);
    },
    removeItem: name => {
        window.history.replaceState({
            ...window.history.state,
            [name]: undefined
        }, '');
    }
}

const createWorkItemReportingStore = (
    idFromRow: (row: any) => RowId,
    initialState: Partial<WorkItemReportingState> | undefined = {}
) => 
    createStore<WorkItemReportingState>()(
        persist(
            (set, get) => createDefaultState(set, get, idFromRow, initialState),
            {
                name: 'workItemReportingStore',
                storage: storage,
                partialize: (state) => ({
                    hasHydrated: state.hasHydrated,
                    currentPage: state.currentPage,
                    filter: state.filter,
                    filterColumns: state.filterColumns,
                    globalSelectAllState: state.globalSelectAllState,
                    initialised: state.initialised,
                    pageSize: state.pageSize,
                    rowData: state.rowData, 
                    selectedData: state.selectedData,
                    selectionEnabled: state.selectionEnabled,
                    showFilter: state.showFilter,
                    sortOrder: state.sortOrder,
                    totalRows: state.totalRows,
                    eventType: state.eventType,
                    periodType: state.periodType,
                    startDate: state.startDate,
                    endDate: state.endDate,
                    reportType: state.reportType,
                    groupBy: state.groupBy
                }) as WorkItemReportingState,
                onRehydrateStorage: state => () => state.setHasHydrated()
            }
        )
    );

const createDefaultState = (set: SetterFunction<WorkItemReportingState>,
    get: () => WorkItemReportingState,
    idFromRow: (row: any) => RowId,
    initialState: Partial<WorkItemReportingState>
): WorkItemReportingState => {
    
    return {
        id: crypto.randomUUID().substring(0, 5),
        graphQLQueryName: 'allWorkItems',
        graphQLQueryColumns: defaultQueryColumns,
        uniqueSortColumn: 'workItemId',
        mappingFunction: defaultMappingFunction,

        paged: true,

        hasHydrated: false,
        setHasHydrated: () => set({ hasHydrated: true }),

        clearFilter: () => {
            set({
                currentPage: 0,
                filter: []
            });
            const state = get();
            state.updateRowData(false);
        },
    
        clearSelection: () => clearSelection(set),
    
        idFromRow: idFromRow,
    
        initialised: false,
    
        getExportCsv: () => getExportCsv(get),
    
        globalSelectAllState: 'UNAVAILABLE',
        
        rowData: {
            data: [],
            isFetching: false,
            isCancelled: false,
            isError: false
        },
        rowDataCancellablePromise: null,
    
        selectedData: [],
        selectionEnabled: false,
    
        globalSelectAllClick: () => globalSelectAllClick(set, get),
    
        eventTypes: eventTypes,
        periodTypes: [ 'On', 'Between' ],
        reportTypes: [ 'Item List' ],
    
        groupBy: null,
        setGroupBy: groupBy => setGroupBy(set, get, groupBy),

        setGraphQLQueryName: (name: string) => setGraphQLQueryName(set, name),
    
        eventType: defaultEventType,
    
        setCurrentPage: (page: number) => setCurrentPage(set, get, page),
    
        setEventType: eventType => setEventType(set, get, eventType),
    
        setPageSize: pageSize => setPageSize(set, get, pageSize),
    
        startDate: defaultStartDate,
        setStartDate: startDate => setStartDate(set, get, startDate),
    
        endDate: defaultEndDate,
        setEndDate: endDate => setEndDate(set, get, endDate),
    
        periodType: defaultPeriodType,
        setPeriodType: periodType => setPeriodType(set, get, periodType),
    
        reportType: 'Item List',
        setReportType: reportType => setReportType(set, get, reportType),
    
        filterCollapsed: true,
        setFilterCollapsed: (collapsed: boolean) => setFilterCollapsed(set, collapsed),
        filter: [],
        filterColumns: filterColumns,
    
        setFilterNode: (node: FilterNode) => {
            setFilterNode(set, node);
            
            const state = get();
            if (state.reportType === 'Item List') {
                state.updateRowData(false);
            } else {
                state.updateChartData();
            }
        },
    
        removeFilterNode: (nodeId: string) => {
            removeFilterNode(set, nodeId);
            
            const state = get();
            if (state.reportType === 'Item List') {
                state.updateRowData(false);
            } else {
                state.updateChartData();
            }
        },
        
        setShowFilter: show => set({ showFilter: show }),
        
        // Paging
        currentPage: 0,
        pageSize: 100, // TODO: Still needs to be brought from user prefs
        totalRows: 0,
    
        sortOrder: [],
    
        showFilter: false,
    
        toggleSortColumn: (column: string, uniqueSortColumn: string) => toggleSortColumn(set, get, column, uniqueSortColumn),
    
        selectAllClick: () => selectAllClick(set, get),
        rowSelectClick: (row: WorkItem) => rowSelectClick(set, get, row),
        updateRowData: (retainSelection: boolean) => updateReportRowData(set, get, retainSelection),
    
        chartData: {
            data: null,
            isError: false,
            isCancelled: false,
            isFetching: false
        },
        chartDataCancellablePromise: null,
    
        updateChartData: () => updateReportChartData(set, get),

        fixedParameters: [],
        setFixedParameters: (parameters: QueryParameter[]) => set({ fixedParameters: parameters}),

        groupingColumn: null,
    
        ...initialState
    };
}

const updateReportChartData = (set: SetterFunction<WorkItemReportingState>, get: () => WorkItemReportingState) => {
    set(state => ({
        graphQLQueryName: 'timeSeriesWorkItemReport',
        fixedParameters: getChartClauseForEventTypeAndDates(state.eventType, state.groupingColumn, state.periodType, state.startDate, state.endDate)
    }))

    updateChartData(set, get);
}

const updateReportRowData = (set: SetterFunction<WorkItemReportingState>, get: () => WorkItemReportingState, retainState: boolean) => {
    set(state => ({
        graphQLQueryName: 'allWorkItems',
        fixedParameters: getClauseForEventTypeAndDates(state.eventType.eventTypes, state.periodType, state.startDate, state.endDate)
    }));

    updateRowData(set, get, retainState)
}

interface ChartDataset {
    metricType: MetricType
    groupingColumn: string | null
    eventTypes: number[]
}

registerParameterApplicator(PARAMETER_TYPE.CHART_DATASET, {
    createOrAppendParameterValue: (existingValue: ChartDataset, newValue: ChartDataset) => {
        return newValue;
    },

    finalise: (value: ChartDataset) => {
        const props = [];
        props.push(`metricType:${value.metricType}`);
        if (value.groupingColumn) {
            props.push(`groupingColumn:"${value.groupingColumn}"`);
        }

        if (Array.isArray(value.eventTypes) && value.eventTypes.length > 0) {
            props.push(`eventTypes:[${value.eventTypes.join()}]`);
        }

        return `[{${props.join()}}]`;
    }
})

const getChartClauseForEventTypeAndDates = (event: ReportingEventTypeSpec, groupingColumn: string | null, periodType: PeriodType, startDate: Date, endDate: Date | null): QueryParameter[] => {
    const result: QueryParameter[] = [];

    result.push({
        name: 'datasets',
        type: PARAMETER_TYPE.CHART_DATASET,
        value: {
            eventTypes: event.eventTypes,
            groupingColumn: groupingColumn,
            metricType: event.metricType
        } as ChartDataset
    })

    if (periodType === 'On' || endDate === null) {
        result.push({
            name: 'fixedFilter.reportingEventDate',
            type: PARAMETER_TYPE.DATE,
            value: { on: [ startDate ] }
        });
    } else {
        result.push({
            name: 'fixedFilter.reportingEventDate',
            type: PARAMETER_TYPE.DATE,
            value: { 
                onOrAfter: startDate,
                onOrBefore: endDate
            }
        });
    }

    return result;
}

const setEventType = (set: SetterFunction<WorkItemReportingState>, get: () => WorkItemReportingState, eventType: ReportingEventTypeSpec) => {
    set(state => {

        const calculatedReportType = eventType.availableReportTypes.includes(state.reportType)
            ? state.reportType
            : eventType.availableReportTypes[0];

        const calculatedPeriodTypes = [] as PeriodType[];
        if (eventType.availableReportTypes.includes('Item List')) {
            calculatedPeriodTypes.push('On');
        }

        calculatedPeriodTypes.push('Between');

        const calculatedPeriodType = calculatedPeriodTypes.includes(state.periodType)
            ? state.periodType
            : calculatedPeriodTypes[0];

        let calculatedStartDate = state.startDate;
        let calculatedEndDate = state.endDate;
        if (calculatedPeriodType === 'Between' && state.endDate === null) {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            if (state.startDate < today) {
                calculatedEndDate = today;
            } else {
                calculatedStartDate = addMonths(today, -1);
                calculatedEndDate = today;
            }
        }

        const newState: Partial<WorkItemReportingState> = {
            eventType: eventType,
            reportTypes: eventType.availableReportTypes,
            reportType: calculatedReportType,
            periodTypes: calculatedPeriodTypes,
            periodType: calculatedPeriodType,
            startDate: calculatedStartDate,
            endDate: calculatedEndDate
        };

        return newState;
    });

    const state = get();
    if (state.reportType === 'Item List') {
        state.updateRowData(false);
    } else {
        state.updateChartData();
    }
}

const setPeriodType = (set: SetterFunction<WorkItemReportingState>, get: () => WorkItemReportingState, periodType: PeriodType) => {
    set(state => {
        const newState: Partial<WorkItemReportingState> = {
            periodType: periodType,
            reportTypes: ([ 'Item List', 'Line Chart' ] as ReportType[]).filter(rt => rt !== 'Line Chart' || periodType === 'Between'),
            endDate: state.endDate === null ? min([new Date(), addDays(addMonths(state.startDate, 1), -1)]) : state.endDate,
        };

        if (periodType === 'On' && state.reportType === 'Line Chart') {
            newState.reportType = 'Item List';
            newState.groupBy = null;
        }

        return newState;
    });

    const state = get();
    if (state.reportType === 'Item List') {
        state.updateRowData(false);
    } else {
        state.updateChartData();
    }
}

const setStartDate = (set: SetterFunction<WorkItemReportingState>, get: () => WorkItemReportingState, startDate: Date) => {
    set(state => {
        const endDate = state.periodType === 'Between' && state.endDate !== null && state.endDate < startDate ? startDate : state.endDate;
        
        return {
            startDate: startDate,
            endDate: endDate,
        };
    });

    const state = get();
    if (state.reportType === 'Item List') {
        state.updateRowData(false);
    } else {
        state.updateChartData();
    }
}

const setEndDate = (set: SetterFunction<WorkItemReportingState>, get: () => WorkItemReportingState, endDate: Date) => {
    set(state => {
        const startDate = state.startDate > endDate ? endDate : state.startDate

        return {
            startDate: startDate,
            endDate: endDate,
        };
    });

    const state = get();
    if (state.reportType === 'Item List') {
        state.updateRowData(false);
    } else {
        state.updateChartData();
    }
}

const setReportType = (set: SetterFunction<WorkItemReportingState>, get: () => WorkItemReportingState, reportType: ReportType) => {
    set(state => { 
        const newState: Partial<WorkItemReportingState> = {
            reportType: reportType 
        }

        if (reportType !== 'Line Chart' && state.groupBy !== null) {
            newState.groupBy = null;
        }

        return newState;
    });

    const state = get();
    if (state.reportType === 'Item List') {
        state.updateRowData(false);
    } else {
        state.updateChartData();
    }
}

const setGroupBy = (set: SetterFunction<WorkItemReportingState>, get: () => WorkItemReportingState, groupBy: Grouping | null) => {
    set(state => ({
        groupBy: groupBy,
        groupingColumn: groupBy?.groupingColumn
    }));

    const state = get();
    state.updateChartData();
}

const getClauseForEventTypeAndDates = (eventTypes: number[], periodType: PeriodType, startDate: Date, endDate: Date | null): QueryParameter[] => {
    const result: QueryParameter[] = [];

    if (eventTypes.length === 1 && eventTypes[0] < 0) {
        result.push({
            name: 'metric',
            type: PARAMETER_TYPE.RAW_STRING,
            value: eventTypes[0] === -1 ? 'Active' : 'Overdue'
        });
    } else {
        result.push({
            name: 'fixedFilter.reportingEventTypeId',
            type: PARAMETER_TYPE.NUMBER,
            value: eventTypes
        });
    }

    if (periodType === 'On' || endDate === null) {
        result.push({
            name: 'fixedFilter.reportingEventDate',
            type: PARAMETER_TYPE.DATE,
            value: { on: [ startDate ] }
        });
    } else {
        result.push({
            name: 'fixedFilter.reportingEventDate',
            type: PARAMETER_TYPE.DATE,
            value: { 
                onOrAfter: startDate,
                onOrBefore: endDate
            }
        });
    }

    return result;
}

const setFilterCollapsed = (set: SetterFunction<WorkItemReportingState>, collapsed: boolean) => set({ filterCollapsed: collapsed });

export { createWorkItemReportingStore, WorkItemReportingStoreContext, useWorkItemReportingState }
export type { Grouping, PeriodType, ReportType, ReportingEventTypeSpec }