const workItemStatus = {
    RECEIVED: 1,
    QUEUED: 2,
    PROCESSING: 3,
    AWAITING_INFORMATION: 4,
    CORRECTED: 5,
    OVERRIDE_REQUEST: 6,
    OVERRIDE_APPROVED: 7,
    REFERRED: 8,
    CORRECTED_AT_SOURCE: 9,
    OVERRIDE_APPROVED_SAME_DATA_ONLY: 10
};

const permissions = {
    VIEW_DISTRIBUTOR_WORK_QUEUE: 1,
    ALLOCATE_WORK_ITEMS_TO_QUEUE: 2,
    VIEW_TEAM_WORK_QUEUE: 3,
    PICK_WORK_ITEM_FROM_QUEUE: 4,
    RETURN_WORK_ITEM_TO_QUEUE: 5,
    REALLOCATE_TO_DISTRIBUTOR_WORK_QUEUE: 6,
    MARK_WORK_ITEM_AS_CORRECTED: 7,
    MARK_WORK_ITEM_AS_INFORMATION_REQUIRED: 8,
    REQUEST_OVERRIDE: 9,
    VIEW_REQUEST_ACCEPTANCE_QUEUE: 10,
    APPROVE_DENY_OVERRIDE: 11,
    VIEW_LIST_OF_ACCEPTED_REPORTABLE_EVENTS: 12,
    CHANGE_STATUS_OF_ACCEPTED_REPORTABLE_EVENTS: 13,
    VIEW_LIST_OF_USERS: 14,
    EDIT_USER: 15,
    ADD_USER: 16,
    DELETE_USER: 17,
    ADD_USER_TO_TEAM: 18,
    VIEW_LIST_OF_TEAMS: 19,
    EDIT_TEAM: 20,
    ADD_TEAM: 21,
    DELETE_TEAM: 22,
    VIEW_LIST_OF_ROLES: 23,
    ADD_ROLE: 24,
    EDIT_ROLE: 25,
    DELETE_ROLE: 26,
    VIEW_LIST_OF_RULES: 27,
    EDIT_RULE: 28,
    ENABLE_DISABLE_RULE: 29,
    VIEW_ALERT_USAGE: 30,
    CAN_RECEIVE_ALERTS: 31,
    EDIT_ALERT_USAGE: 32,
    VIEW_SYSTEM_SETTINGS: 33,
    EDIT_SYSTEM_SETTINGS: 34,
    VIEW_REPORTS: 35,
    VIEW_DASHBOARD: 36,
    VIEW_LIST_OF_DASHBOARDS: 37,
    EDIT_OWN_DASHBOARDS: 38,
    EDIT_OTHER_USERS_DASHBOARDS: 39,
    VIEW_LIST_OF_REFERRED_WORK_ITEMS: 40,
    SELECT_REFERRED_WORK_ITEM_TO_REVIEW: 41,
    VIEW_USER_ACTIVITY: 44
};

const noteType = {
    GENERAL: 9
};

const organisationConfig = {
    DEFAULT_TARGET_TURNAROUND_TIME: 1
};

const displayConfig = {
    USE_DEMO_DASHBOARD: 1
};

const workItemReportingEventType = {
    CREATED: 1,
    DISTRIBUTED: 2,
    ACCEPTED: 3,
    OVERRIDE_REQUESTED: 4,
    OVERRIDE_REJECTED: 5,
    OVERRIDE_APPROVED: 6,
    OVERRIDE_APPROVED_SAME_DATA_ONLY: 7,
    REFERRED: 8,
    RETURNED_TO_QUEUE: 9,
    CORRECTED: 10,
    CORRECTED_AT_SOURCE: 11,
    OVERRIDE_CANCELLED: 12,
    OVERRIDE_EXPIRED: 13,

    // Added September 2024
    MARKED_AS_INFORMATION_REQUIRED: 14,
    REFERRAL_CANCELLED: 15,
    OVERRIDE_REQUEST_CANCELLED: 16,
    RETURNED_TO_DISTRIBUTION: 17
};

export { noteType, organisationConfig, permissions, workItemStatus, displayConfig, workItemReportingEventType };